<template>
  <div
    class="gift-ticket mb-4"
    :class="{ 'border-medium --border-prm': isSaleItem, '': !isSaleItem }"
  >
    <div
      class="d-flex align-center pa-2 rounded-top-5 "
      :class="{
        'bg-very-light-shade-gray': isSaleItem,
        'bg-primary--grad': !isSaleItem
      }"
    >
      <label
        class="text-medium pl-5 pr-10"
        :class="{ '--prm-light': isSaleItem, '--white': !isSaleItem }"
      >
        {{ $t("common.numberOfPointsRequired") }}
      </label>
      <span
        class="text-x-heading font-weight-bold"
        :class="{ '--prm-light': isSaleItem, '--white': !isSaleItem }"
      >
        {{ pointsRequired | toThousands }}{{"pt/" + $t("unit.ticket")}}
      </span>
    </div>

    <div
      class="pa-4"
      :class="{ 'bg-white': isSaleItem, 'bg-w-silver': !isSaleItem }"
    >
      <h5 class="text-title --w-bold --c-dark-gray">{{ name }}</h5>
      <h4
        class="text-normal  mt-2"
        :class="{ '--prm': isSaleItem, '--smoke': !isSaleItem }"
      >
        {{ place }}
      </h4>
      <div v-if="saleEndSoon" class="--red">
        交換期限： {{ saleEndAt | jaLongDateTime }}
      </div>
      <v-divider class="my-4" />
      <div
        class="d-flex align-center"
        :class="{ 'justify-center': isSaleItem }"
      >
        <div class="flex-shrink-0 mb-n2">
          <IconTicket color="var(--color__primary)" />
        </div>
        <div class="text-large--szm --prm-light font-weight-bold mx-2">
          <span>{{ formatCurrency(totalTickets) }}</span>
          <label class="text-medium font-weight-medium">
            {{ totalTickets > 1 ? $t("unit.tickets") : $t("unit.ticket") }}
          </label>
        </div>
        <v-btn
          elevation="0"
          @click="$emit('openDialog')"
          v-if="!isSaleItem"
          rounded
          width="152"
          height="39"
          class="width-x-small bg-orange --white flex-shrink-0 ml-auto"
        >
          {{ $t("buttons.enterNumberOfTickets") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import IconTicket from '../../components/icons/IconTicket'
import { formatCurrency } from '@/utils/format'
import { giftTicketSaleEndSoon } from '@/utils/get-date'

export default {
  name: 'GiftTicketItem',
  components: {
    IconTicket
  },
  props: {
    isSaleItem: {
      type: Boolean,
      default: false
    },
    pointsRequired: Number,
    name: String,
    place: String,
    totalTickets: {
      type: Number,
      default: 0
    },
    saleEndAt: {
      type: [Date, String],
      required: false
    }
  },
  computed: {
    saleEndSoon () {
      return giftTicketSaleEndSoon(this.saleEndAt)
    }
  },
  methods: {
    formatCurrency
  }
}
</script>
