<template>
  <div class="form-container">
    <h1 class="text-heading --prm --w-bold bg-white text-center py-6">
      {{ $t("menu.main.exchangePoints") }}
    </h1>
    <div class="form-container pb-15 p-relative">
      <div class="px-5 pt-7">
        <!--=========== START Gift-ticket-list ===========-->
        <GiftTicketItem
          v-for="item in giftTicketList"
          :key="item.id"
          :pointsRequired="item.pointsRequired"
          :name="item.name"
          :place="item.place"
          :totalTickets="numOfTickets[item.id] ? numOfTickets[item.id] : 0"
          :saleEndAt="item.saleEndAt"
          :blue="false"
          @openDialog="openExchangePointsDialog(item.id)"
        />
        <!--=========== END Gift-ticket-list ===========-->
      </div>

      <!--=========== START Available-points ===========-->
      <div
        class="bg-primary-light pos-sticky bottom-0 py-3 px-5 --white d-flex justify-space-between align-center"
      >
        <div class="d-flex">
          <span
            class="text-medium mr-5 font-weight-light"
            style="max-width: 64px;"
          >
            {{ $t("common.availablePoints") }}
          </span>
          <span class="text-huge font-weight-bold">
            {{ availablePoints }}pt
          </span>
        </div>
        <!-- Order-button -->
        <v-btn
          elevation="0"
          @click="handleOrder"
          width="106"
          height="39"
          rounded
          class="bg-white --prm-light"
        >
          {{ $t("buttons.order") }}
        </v-btn>
      </div>
      <!--=========== END Available-points ===========-->

      <!--=========== START Exchange-points-dialog ===========-->
      <v-dialog
        v-if="showDialog"
        v-model="showDialog"
        width="378"
        content-class="bg-silver"
      >
        <div class="text-right">
          <button
            @click.stop="selectedTicketId = null"
            class="btn-close bg-primary"
          >
            <div class="btn-close__icon"/>
          </button>
        </div>

        <div class="px-5">
          <h2 class="text-heading --prm --w-bold pt-5 py-6 text-center">
            {{ $t("menu.main.exchangePoints") }}
          </h2>
          <!-- START Points-redeemable -->
          <div class="text-center mb-4">
            <span class="text-large--szm --orange font-weight-bold">
              {{ newNumOfTicket * selectedProduct.pointsRequired | toThousands }}
            </span>
            <span class="text-medium --orange">
              {{
                newNumOfTicket * selectedProduct.pointsRequired > 1
                  ? $t("unit.points")
                  : $t("unit.point")
              }}
            </span>
            <span class="text-medium"> {{ $t("common.beRedeemed") }} </span>
          </div>
          <!-- END Points-redeemable -->

          <h5 class="text-title--c-dark-gray">
            {{ selectedProduct.name }}
          </h5>
          <v-divider class="mt-4 mb-5"/>

          <!-- START Enter-number-of-tickets -->
          <v-form
            ref="exchangePointForm"
            lazy-validation
            class="d-flex justify-space-between"
          >
            <div class="d-flex mb-4">
              <div class="text-normal col-7 --dark font-weight-medium ">
                {{ $t("giftTicket.enterNumOfTicket.line1") }}
                {{ $t("giftTicket.enterNumOfTicket.line2") }}
              </div>
              <div class="col-4 col d-flex">
                <v-text-field
                  :rules="numberRules"
                  solo
                  flat
                  outlined
                  type="number"
                  v-model="newNumOfTicket"
                  class="rounded-lg center-input fixed-width--medium"
                  :oninput="$rules.onInputPreventNegative"
                  min="0"
                />
                <label class="text-normal mt-5 ml-2">
                  {{ $t("unit.tickets") }}
                </label>
              </div>
            </div>
          </v-form>
          <v-btn
            elevation="0"
            @click="submitExchangePoint"
            rounded
            x-large
            block
            class="text-heading--c-white mb-11"
            color="btn-grad--orange"
          >
            <span class="mx-auto">{{ $t("buttons.confirmNumberOfTickets") }}</span>
            <IconRight class="mx-0"/>
          </v-btn>
        </div>
        <!-- END Enter-number-of-tickets -->
      </v-dialog>
      <!--=========== END Exchange-points-dialog ===========-->

      <!--=========== START Insufficient-points-dialog ===========-->
      <GiftTicketExchangePointsInsufficientDialog
        :visible="dialogInsufficient"
        @close="dialogInsufficient = false"
        :minimumPoints="cartTotal"
        :currentPoints="pointToUse"
      />
      <!--=========== END Insufficient-points-dialog =============-->
    </div>
  </div>
</template>

<script>
import GiftTicketItem from '../../../components/GiftTicketItem'
import GiftTicketExchangePointsInsufficientDialog from './components/GiftTicketExchangePointsInsufficientDialog.vue'
import IconRight from '../../../components/icons/IconRight'
import {
  formatCurrency
} from '@/utils/format'
import mixin from '../mixin'

export default {
  name: 'GiftTicketExchangePoints',
  mixins: [mixin],
  components: {
    GiftTicketItem,
    GiftTicketExchangePointsInsufficientDialog,
    IconRight
  },
  methods: {
    formatCurrency,
    openExchangePointsDialog (id) {
      this.selectedTicketId = id
      this.newNumOfTicket = this.selectedTicketNumber
    },
    submitExchangePoint () {
      if (this.$refs.exchangePointForm.validate()) {
        this.$store.commit('putPointExchangeToCart', {
          id: this.selectedTicketId,
          quantity: this.newNumOfTicket ? parseInt(this.newNumOfTicket) : 0
        })
        this.selectedTicketId = null
        this.checkRemainingPoint()
      }
    },
    handleOrder () {
      if (this.checkRemainingPoint()) {
        this.$router.push('/home/exchange-points/order')
      }
    },
    checkRemainingPoint () {
      if (this.availablePoints >= this.cartTotal) {
        return true
      } else {
        this.dialogInsufficient = true
        return false
      }
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('loadRemainingPoints')
        await this.$store.commit('setPointExchangeContractId', this.parsedContractId)
      })
    })
  },
  computed: {
    points () {
      return this.$store.getters.remainingPoints
    },
    parsedContractId () {
      const parsed = parseInt(this.contractId)
      if (isNaN(parsed)) return null
      return parsed
    },
    selectedProduct () {
      if (!this.selectedTicketId) return null
      return this.giftTicketList.find(t => t.id === this.selectedTicketId)
    },
    showDialog: {
      get () {
        return !!this.selectedProduct
      },
      set (v) {
        if (!v) this.selectedTicketId = null
      }
    },
    selectedTicketNumber () {
      return this.numOfTickets[this.selectedTicketId] ? this.numOfTickets[this.selectedTicketId] : 0
    }
  },
  data () {
    return {
      // ------- Exchange-points-dialog --------
      selectedTicketId: null,
      // --------------------------------------
      // ----- Insufficient-points-dialog -----
      dialogInsufficient: false,
      newNumOfTicket: null,
      numberRules: [
        v => !!v.toString().length || this.$t('rules.isRequired'),
        v => (v >= 0 && v < 100000) || this.$t('rules.invalid')
      ]
    }
  }
}
</script>
