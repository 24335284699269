<template>
  <v-dialog
    v-model="isVisible"
    width="378"
    content-class="bg-w-silver text-center"
  >
    <div class="text-right">
      <button @click.stop="$emit('close')" class="btn-close bg-primary">
        <div class="btn-close__icon" />
      </button>
    </div>

    <div class="py-5">
      <div class="text-heading --w-bold --dark-red">
        {{ $t("rules.insufficientPoints") }}
      </div>
      <div
        class="text-medium font-weight-bold d-flex flex-wrap justify-center mt-5 mb-8"
      >
        {{ $t("rules.pointsNeededToRedeem") }}
        <span class="--prm"> {{ minimumPoints | toThousands }}{{ $t("rules.pointsTo") }} </span>
        {{ $t("rules.willBe") }}
      </div>

      <h4 class="text-heading--medium-gray">
        {{ $t("common.currentPoints") }}
      </h4>
      <v-divider class="my-4" />
      <h4 class="text-heading--c-dark">{{ currentPoints.name }}</h4>
      <div
        class="d-flex align-end justify-center text-medium --prm font-weight-bold mb-5"
      >
        <span class="text-large-szlx mr-1 mb-n2"> {{ pointRemains | toThousands}} </span>
        {{ pointRemains > 1 ? $t("unit.points") : $t("unit.point") }}
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'GiftTicketExchangePointsInsufficientDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    minimumPoints: {
      type: Number,
      default: 0
    },
    currentPoints: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    pointRemains () {
      return this.currentPoints?.pointRemains ?? 0
    }
  }
}
</script>
